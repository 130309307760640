import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/anti/fraud',
    name: 'antiFraud',
    meta: {
      title: "防骗指南",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AntiFraud.vue')
  },
  {
    path: '/anti/wmgy',
    name: 'antiWmgy',
    meta: {
      title: "文明公约",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Wmgy.vue')
  },
  {
    path: '/agreement/anchor-join',
    name: 'anchorJoinAgreement',
    meta: {
      title: "技师入驻协议",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AnchorJoinAgreement.vue')
  },
  {
    path: '/agreement/privacy',
    name: 'privacyAgreement',
    meta: {
      title: "隐私协议",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyAgreement.vue')
  },
  {
    path: '/agreement/user',
    name: 'userAgreement',
    meta: {
      title: "用户协议",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserAgreement.vue')
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      title: "分享",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Share.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title
  } else {
    document.title = '倍到位'
  }
  next()
})

export default router
